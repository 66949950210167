/* eslint-disable react/jsx-max-depth */
import { parse, format } from "date-fns";
import * as React from "react";
import { generate } from "shortid";
import type { ApiRequest, PersonRequest } from "@plinknz/tah-website-elements";
import { WHATA_ENDPOINTS } from "@plinknz/tah-website-elements";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { whataRequest } from "../../../../service/member/whata";
import * as ROUTES from "../../../../config/router";
import { Loader } from "../../../../components/loader";
import { PageTitle } from "../../../../components/page-title";
import { Hero } from "../../../../components/hero";
import { UserContext } from "../../../../service/reducers/user";
import { UpdateDetails } from "./update-details.component";
import { UpdateGroups } from "./update-groups.component";

interface DetailsComponentProps {
    whata?: ApiRequest;
}

export const DetailsComponent = ({
    whata = whataRequest,
}: DetailsComponentProps) => {
    const {
        state: { user, member },
        dispatch,
    } = React.useContext(UserContext);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();
    const fetchUser = async (): Promise<void> => {
        if (!user?.id) {
            return;
        }

        setLoading(true);

        try {
            const { data } = await whata.get<PersonRequest | string>(
                `${WHATA_ENDPOINTS.person()}/${user.id}`
            );

            if (typeof data === "string" && data.includes("Login")) {
                history.push(ROUTES.membersLogout());
            }

            if (data) {
                dispatch({ type: "set_member", member: data as PersonRequest });
            }
        } catch (error: unknown) {
            console.warn(error);

            if (location.pathname !== ROUTES.membersLogin()) {
                history.push(ROUTES.membersLogin());
            }
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        fetchUser();
    }, [user?.id]);

    const renderLine = (v: string | string[]) => {
        if (!v) {
            return null;
        }

        if (Array.isArray(v)) {
            return (
                <React.Fragment key={generate()}>
                    {v.map(renderLine)}
                </React.Fragment>
            );
        }

        return <p key={generate()}>{v}</p>;
    };
    const renderRow = (
        columns:
            | (string | null)[]
            | (string | string[] | undefined)[]
            | (string | string[][] | undefined)[]
    ) => (
        <tr key={generate()} className="table-row">
            {columns.map((value) => (
                <td className="table-cell" key={generate()}>
                    {(Array.isArray(value) && value.map(renderLine)) ||
                        value ||
                        "-"}
                </td>
            ))}
        </tr>
    );
    const renderTable = (
        rows: (
            | (string | null)[]
            | (string | string[] | undefined)[]
            | (string | string[][] | undefined)[]
        )[]
    ) => (
        <table className="table">
            <tbody>{rows.map(renderRow)}</tbody>
        </table>
    );

    const rows = [
        ["Title", member?.title],
        ["First Name", member?.firstName],
        ["Middle names", member?.middleNames],
        ["Surname", member?.surname],
        ["Aliases", member?.aliases],
        ["Email", member?.email?.map(({ value }) => value)],
        [
            "Address",
            member?.address?.map((address) => [
                address.line1,
                address.line2,
                address.suburb,
                `${address.city} ${address.postcode}`,
                address.state,
                address.country,
            ]),
        ],
        ["Phone", member?.phone?.[0]?.value],
        ["Gender", member?.gender],
        [
            "Date of Birth",
            member?.dob
                ? format(
                      parse(member?.dob, "yyyymmdd", new Date()),
                      "dd/mm/yyyy"
                  )
                : null,
        ],
        ["Occupation", member?.occupation],
    ];
    const groupRows = [
        ["Marae", member?.marae],
        ["Hapu", member?.hapu],
        ["Takiwa", member?.takiwa],
    ];

    return (
        <div data-testid="moku-details">
            <PageTitle title="Mōku" />
            <Hero title="Mōku" subtitle="My Details" />
            <div className="constrain-width">
                <Switch>
                    <Route path={ROUTES.membersDetailsEdit()}>
                        <UpdateDetails fetchUser={fetchUser} />
                    </Route>
                    <Route path={ROUTES.membersDetailsGroups()}>
                        <UpdateGroups fetchUser={fetchUser} />
                    </Route>
                    <Route path={ROUTES.membersDetails()}>
                        {isLoading && (
                            <div className="splash">
                                <Loader size="large" />
                            </div>
                        )}
                        {member && (
                            <>
                                {renderTable(rows)}
                                <Link
                                    to={ROUTES.membersDetailsEdit()}
                                    className="button primary"
                                    type="button">
                                    Edit my information{" "}
                                    <i className="button-icon || fa fa-pencil-alt" />
                                </Link>
                                <hr className="breakline" />
                                {renderTable(groupRows)}
                                <Link
                                    to={ROUTES.membersDetailsGroups()}
                                    className="button primary"
                                    type="button">
                                    Change my hapu, marae, or takiwa
                                    <i className="button-icon || fa fa-pencil-alt" />
                                </Link>
                            </>
                        )}
                    </Route>
                </Switch>
            </div>
        </div>
    );
};
