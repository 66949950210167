import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { canUseDom } from "@plinknz/tah-website-elements";
import * as ROUTES from "../../config/router";
import { PageTitle } from "../../components/page-title";
import { UserContext } from "../../service/reducers/user";
import { PrivateRoute } from "../../components/private-route";
import { DetailsComponent } from "./main/details/details.component";
import { Login } from "./login/login.component";
import { Logout } from "./login/logout.component";
import { MemberIndexPage } from "./main/member-index.component";
import { MemberPage } from "./main/member-page.component";

export const Members = () => {
    const {
        state: { isLoggedIn, loading },
    } = React.useContext(UserContext);

    if (!canUseDom()) {
        return null;
    }

    return (
        <>
            <PageTitle title="Mema" />
            <Switch>
                <Route path={ROUTES.membersLogin()}>
                    <Login />
                </Route>
                <Route path={ROUTES.membersLogout()}>
                    <Logout />
                </Route>
                <PrivateRoute
                    loading={loading}
                    isLoggedIn={isLoggedIn}
                    path={ROUTES.membersDetails()}>
                    <DetailsComponent />
                </PrivateRoute>
                <PrivateRoute
                    exact
                    loading={loading}
                    isLoggedIn={isLoggedIn}
                    path={ROUTES.members()}>
                    <MemberIndexPage />
                </PrivateRoute>
                <PrivateRoute
                    loading={loading}
                    isLoggedIn={isLoggedIn}
                    path={`${ROUTES.members()}/*`}>
                    <MemberPage />
                </PrivateRoute>
            </Switch>
        </>
    );
};
