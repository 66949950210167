import * as React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ApiRequest, WHATA_ENDPOINTS } from "@plinknz/tah-website-elements";
import * as ROUTES from "../../../../config/router";
import { EMAIL_REGEX } from "../../../../config/regex";
import { Field } from "../../../../components/form/field";
import { UserContext } from "../../../../service/reducers/user";
import { whataRequest } from "../../../../service/member/whata";
import { parseError } from "../../../../utility/parse-error-html";

interface EmailLoginFormProps {
    loginWithToken: (token: string) => void;
    whata?: ApiRequest;
}

interface FormData {
    email: string;
    password: string;
}

export const EmailLoginForm = ({
    loginWithToken,
    whata = whataRequest,
}: EmailLoginFormProps) => {
    const {
        state: { error },
        dispatch,
    } = React.useContext(UserContext);
    const { register, handleSubmit, errors, trigger, getValues } =
        useForm<FormData>();

    async function handleFormSubmit(data: FormData) {
        dispatch({ type: "loading", isLoading: true });

        try {
            const whataResponse = await whata.post<{ token: string } | string>(
                WHATA_ENDPOINTS.login(),
                {
                    username: data.email,
                    password: data.password,
                }
            );

            if (
                typeof whataResponse.data !== "string" &&
                whataResponse?.data?.token
            ) {
                loginWithToken(whataResponse?.data.token);
            } else if (typeof whataResponse.data === "string") {
                const responseError =
                    parseError(whataResponse.data) || undefined;

                throw new Error(responseError);
            }
        } catch (submitError: unknown) {
            console.warn(submitError);
            let errorMessage = "Something went wrong trying to log you in";

            if (submitError instanceof Error) {
                errorMessage = submitError.message;
            }

            dispatch({ type: "error", error: errorMessage });
        } finally {
            dispatch({ type: "loading", isLoading: false });
        }
    }

    const isValid = () =>
        !errors.email &&
        !errors.password &&
        getValues().email &&
        getValues().password;

    return (
        <form
            className="form"
            data-testid="email-form"
            onSubmit={handleSubmit(handleFormSubmit)}>
            <Field
                label="Email Address"
                name="email"
                error={{
                    hasError: !!errors.email,
                    message: "Please enter a valid email address",
                }}
                ref={register({ required: true, pattern: EMAIL_REGEX })}
                placeholder="Email address"
                onChange={async (e) => trigger(e.currentTarget.name as "email")}
                type="text"
                data-testid="email"
                required
            />
            <Field
                label="Password"
                name="password"
                error={{
                    hasError: !!errors.password,
                    message: "Please enter your password",
                }}
                ref={register({ required: true })}
                placeholder="Password"
                onChange={async (e) =>
                    trigger(e.currentTarget.name as "password")
                }
                type="password"
                data-testid="password"
                required
            />
            {error && (
                <p data-testid="email-form-error" className="field-error">
                    {error}
                </p>
            )}
            <div className="form-navigation">
                <button
                    className="button primary"
                    type="submit"
                    data-testid="login-button"
                    disabled={!isValid()}>
                    Login
                </button>
                <Link
                    to={ROUTES.membersLoginReset()}
                    className="button tertiary next">
                    Reset password
                </Link>
            </div>
            <br />
            <Link to={ROUTES.membersLogin()}>Show all login options</Link>
        </form>
    );
};
