/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@apollo/client";
import type { NavigationData, Page } from "@plinknz/tah-website-elements";
import {
    classNames,
    getFetchPolicy,
    GET_NAVIGATION,
    showLoad,
} from "@plinknz/tah-website-elements";
import * as React from "react";
import { NavLink, useHistory } from "react-router-dom";
import shortid from "shortid";
import { MemberNavigation } from "./member-navigation";

interface AppWindow extends Window {
    Snipcart: any;
}

interface Global extends NodeJS.Global {
    window: AppWindow;
}

const appGlobal = global as unknown as Global;

export const { window } = appGlobal;

export const Navigation: React.FunctionComponent = () => {
    const { data, loading, error } = useQuery<NavigationData>(GET_NAVIGATION, {
        fetchPolicy: getFetchPolicy(),
    });
    const [isOpen, toggleOpen] = React.useState(false);
    const [activeParent, setActiveParent] = React.useState<string | null>(null);
    const history = useHistory();
    const navClass = classNames("navigation", {
        "is-active": isOpen,
    });
    const [isCartVisible, setCartVisibility] = React.useState(false);
    const renderItem = (page: Page, cta = false) => {
        const hasChildPages = page.children && page.children.length > 0;
        const itemIsOpen = activeParent === page.slug;
        const itemClass = classNames("navigation-item", {
            "is-open": itemIsOpen,
            cta,
        });
        const slug = page.slug === "home" ? "/" : page.slug;
        const childClass = classNames("navigation-item-children");

        if (cta) {
            return (
                <li className={itemClass} key={shortid.generate()}>
                    <NavLink
                        to={slug}
                        activeClassName="is-active"
                        className="navigation-link || button secondary">
                        {page.title}
                    </NavLink>
                </li>
            );
        }

        return (
            <li className={itemClass} key={shortid.generate()}>
                <div className="navigation-item-inner">
                    <NavLink
                        to={slug}
                        activeClassName="is-active"
                        className="navigation-link">
                        {page.title}
                    </NavLink>
                    {hasChildPages && (
                        <button
                            className="navigation-item-toggle"
                            title={itemIsOpen ? "Show less" : "Show more"}
                            type="button"
                            onClick={(event) => {
                                event.stopPropagation();
                                setActiveParent(itemIsOpen ? null : slug);
                            }}>
                            <i className="icon || fas fa-angle-down" />
                        </button>
                    )}
                </div>

                {hasChildPages && (
                    <div className={childClass}>
                        {page.children?.map((childPage) => (
                            <NavLink
                                key={childPage.slug}
                                to={childPage.slug}
                                className="navigation-link">
                                {childPage.title}
                            </NavLink>
                        ))}
                    </div>
                )}
            </li>
        );
    };

    React.useEffect(() => {
        if (!window?.Snipcart?.store) return;

        /* 
        Snipcart doesn't provide an unsubscribe, but navigation shouldn't
        ever unmount. If at any point this component could unmount,
        consider moving the Snipcart subscriber to a provider/Redux to
        prevent memory leaks
        */
        window.Snipcart.store.subscribe(() => {
            const currentValue = window.Snipcart.store.getState();

            setCartVisibility(currentValue.cart.items.count > 0);
        });
    }, [window?.Snipcart?.store]);

    React.useEffect(() => {
        document.body.classList.toggle("navigation-open", isOpen);
        const htmlElement = document.querySelector("html");

        if (htmlElement) {
            htmlElement.style.overflowY = isOpen ? "hidden" : "auto";
        }

        return history.listen(() => toggleOpen(false));
    }, [isOpen]);

    if (error) {
        console.error(error);

        return null;
    }

    if (showLoad(loading)) {
        return null;
    }

    return (
        <nav className={navClass} role="navigation" data-testid="navigation">
            <div
                className="navigation-toggle"
                role="button"
                tabIndex={0}
                onKeyDown={(e) =>
                    e.key === "Enter" ? toggleOpen(!isOpen) : null
                }
                onClick={() => toggleOpen(!isOpen)}>
                <div className="hamburger">
                    <div className="hamburger-wrapper">
                        <div className="hamburger-item line-1" />
                        <div className="hamburger-item line-2" />
                        <div className="hamburger-item line-3" />
                    </div>
                </div>
            </div>

            <div className="navigation-inner">
                <MemberNavigation />
                <ul className="navigation-list">
                    {data?.navigation?.link_to_page.map(({ page }) =>
                        renderItem(page)
                    )}

                    {isCartVisible && (
                        <li className="snipcart-checkout || navigation-item cta">
                            <span className="navigation-link || button primary">
                                <i className="button-icon fa fa-shopping-bag" />
                                <span>Checkout</span>
                                <span className="snipcart-total-price" />
                            </span>
                        </li>
                    )}
                    {data?.navigation?.action &&
                        renderItem(
                            {
                                slug: data.navigation.action.link
                                    .slug as string,
                                title: data.navigation.action.label,
                            },
                            true
                        )}
                </ul>
            </div>
        </nav>
    );
};
