import { useContext, useEffect, useRef, useState } from "react";
import { FlagKey, FLAGS } from "../../config/flags";
import { FlagContext } from "../../service/flags-provider";

export const useFlag = (key: FlagKey) => {
    const name = FLAGS[key];
    const { isEnabled, flags } = useContext(FlagContext);
    const [flag, setFlag] = useState(!!isEnabled(name));
    const flagRef = useRef<boolean>();
    flagRef.current = flag;

    useEffect(() => {
        if (!flags) return;

        const enabled = !!isEnabled(name);

        if (enabled !== flagRef.current) setFlag(!!enabled);
    }, [flags]);

    return flag;
};
