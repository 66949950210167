import { fetch } from "cross-fetch";
import React, { createContext } from "react";
import { ReactNode } from "react-markdown";
import { Flags } from "../config/flags";

export const FlagContext = createContext<{
    isEnabled: (flag: keyof Flags) => boolean;
    flags: Flags | null;
} | null>(null);

export class FlagService {
    private url: string;
    private headers: HeadersInit = {
        "Content-type": "application/json",
        Accept: "application/json",
    };
    private api = fetch;

    public flags: Flags | null;

    constructor({ url = process.env.FLAGS_URL } = {}) {
        this.url = `${url}/flag/?app=rangi-website`;
    }

    get active() {
        return this.flags !== null;
    }

    public async start() {
        const response = await this.api(this.url, {
            method: "GET",
            cache: "default",
            mode: "cors",
            headers: this.headers,
        });
        const data = await response.json();

        this.flags = data;
    }
}

interface FlagProviderProps {
    children: ReactNode;
    client: FlagService;
}
export const FlagProvider = ({ children, client }: FlagProviderProps) => {
    const isEnabled = (flag: keyof Flags) => !!client?.flags[flag];

    return (
        <FlagContext.Provider value={{ isEnabled, flags: client.flags }}>
            {children}
        </FlagContext.Provider>
    );
};
