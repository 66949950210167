import { format } from "date-fns";

const MONTHS_IN_YEAR = 12;

export const getMonthsForYear = (year: number, monthFormat = "MMMM") =>
    Array.from(Array(MONTHS_IN_YEAR))
        .map((_, index) => new Date(year, index))
        .map((date) => ({
            name: format(date, monthFormat),
            value: date,
        }));
