import * as React from "react";
import Headroom from "react-headroom";
import { Link } from "react-router-dom";
import { MemberNavigation } from "./member-navigation";
import { Navigation } from "./navigation";

export interface HeaderProps {
    enableNavigation?: boolean;
}

export const Header = ({ enableNavigation = true }: HeaderProps) => (
    <header className="header" role="banner">
        <Headroom>
            <div className="header-secondary">
                <div className="header-secondary-inner || constrain-width large">
                    <MemberNavigation />
                </div>
            </div>
            <div className="header-inner || constrain-width large">
                <Link to="/" className="header-logo">
                    Ngāti Rangi
                </Link>
                <h1 className="header-title">
                    <Link className="header-title-link" to="/">
                        Ngāti Rangi
                    </Link>
                </h1>
                {enableNavigation && <Navigation />}
            </div>
        </Headroom>
    </header>
);
