import * as React from "react";
import { useHistory } from "react-router-dom";
import { ApiRequest, WHATA_ENDPOINTS } from "@plinknz/tah-website-elements";
import * as ROUTES from "../../../config/router";
import { UserContext } from "../../../service/reducers/user";
import { Loader } from "../../../components/loader";
import { whataRequest } from "../../../service/member/whata";

interface LogoutProps {
    whata?: ApiRequest;
}

export const Logout = ({ whata = whataRequest }: LogoutProps) => {
    const history = useHistory();
    const { dispatch } = React.useContext(UserContext);

    React.useEffect(() => {
        whata
            .get(WHATA_ENDPOINTS.logout())
            .catch(console.warn)
            .finally(() => {
                dispatch({ type: "reset" });
                whata.deleteToken();
                history.push(ROUTES.membersLogin());
            });
    }, []);

    return (
        <div className="splash">
            <Loader size="large" />
        </div>
    );
};
