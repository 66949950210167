function parseHTML(value: string) {
    const tmp = document.implementation.createHTMLDocument();
    tmp.body.innerHTML = value;

    return tmp.body.children;
}

export function parseError(
    htmlError: string,
    fallbackMessage = "There was an error processing your request"
) {
    if (!htmlError) {
        return fallbackMessage;
    }

    const html = parseHTML(htmlError);

    for (const element of Array.from(html)) {
        if (element.nodeName === "PRE") {
            return element.textContent;
        }
        if (element.nodeName === "H1") {
            return element.textContent;
        }
    }

    return fallbackMessage;
}
