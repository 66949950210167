import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { ApiRequest, WHATA_ENDPOINTS } from "@plinknz/tah-website-elements";
import { UserContext } from "../../../../service/reducers/user";
import { Loader } from "../../../../components/loader";
import { MaraePicker } from "../../../../components/form/marae-picker";
import { getTakiwa, Takiwa } from "../../../../config/register/takiwa";
import * as ROUTES from "../../../../config/router";
import { Hapu } from "../../../../config/register/hapu";
import { whataRequest } from "../../../../service/member/whata";
import { registrationApi } from "../../../../service/register/api";
import { Form } from "../../../../components/form/form";
import { Button } from "../../../../components/button";

interface UpdateGroupsProps {
    whata?: ApiRequest;
    registration?: ApiRequest;
    fetchUser: () => void;
}

export const UpdateGroups = ({
    whata = whataRequest,
    registration = registrationApi,
    fetchUser,
}: UpdateGroupsProps) => {
    const {
        state: { loading: isLoading, user, member },
        dispatch,
    } = React.useContext(UserContext);
    const [takiwa, setTakiwa] = React.useState<Takiwa[]>([]);
    const [selection, setSelection] = React.useState<{
        takiwa: number | null;
        marae: number | null;
        hapu: Hapu[];
    }>({
        takiwa: null,
        marae: null,
        hapu: [],
    });
    const [hapu, setHapu] = React.useState<string | null>(null);
    const [error, setError] = React.useState<string | null>(null);
    const history = useHistory();

    const handleSubmit = async () => {
        dispatch({ type: "loading", isLoading: true });

        const maraeEndpoint = WHATA_ENDPOINTS.addToMarae(user.id);
        const hapuEndpoint = WHATA_ENDPOINTS.addToHapu(user.id);
        const takiwaEndpoint = WHATA_ENDPOINTS.addToTakiwa(user.id);

        const maraeMethod = member.MaraeLastUpdated ? "put" : "post";
        const takiwaMethod = member.TakiwaLastUpdated ? "put" : "post";
        const hapuMethod = member.HapuLastUpdated ? "put" : "post";

        const selectedTakiwa = takiwa.find(({ id }) => selection.takiwa === id);
        const selectedMarae = selectedTakiwa?.marae.find(
            ({ id }) => selection.marae === id
        );
        const selectedHapu = selectedMarae?.hapu.find(
            ({ id }) => hapu === id.toString()
        );

        try {
            await whata[takiwaMethod](takiwaEndpoint, {
                takiwa: selectedTakiwa?.description,
                lastUpdated: member.TakiwaLastUpdated,
            });
            await whata[maraeMethod](maraeEndpoint, {
                marae: selectedMarae?.description,
                lastUpdated: member.MaraeLastUpdated,
            });
            await whata[hapuMethod](hapuEndpoint, {
                hapu: selectedHapu?.description,
                lastUpdated: member.HapuLastUpdated,
            });

            fetchUser();

            history.push(ROUTES.membersDetails());
        } catch (e: unknown) {
            setError(
                "Auē, there was an error updating your information. Please reload and try again. "
            );

            console.error(e);
        } finally {
            dispatch({ type: "loading", isLoading: false });
        }
    };

    React.useEffect(() => {
        dispatch({ type: "loading", isLoading: true });

        getTakiwa(registration)
            .then((takiwaItems) => {
                setTakiwa(takiwaItems);
            })
            .catch((e) => {
                console.error(e);
                setError(
                    "Auē, there was an error retrieving the available hapu, marae and takiwa. Please go back and reload the page. "
                );
            })
            .finally(() => dispatch({ type: "loading", isLoading: false }));
    }, []);

    if (isLoading) {
        return (
            <div className="splash">
                <Loader size="large" />
            </div>
        );
    }

    return (
        <Form onSubmit={handleSubmit} data-testid="update-groups-form">
            {takiwa?.length > 0 && (
                <MaraePicker
                    takiwa={takiwa}
                    name="primary"
                    onClick={(values) => {
                        setHapu(null);
                        setSelection(values);

                        if (values.hapu.length === 1) {
                            setHapu(values.hapu[0].id.toString());
                        }
                    }}
                />
            )}
            {selection.hapu.length > 1 && (
                <Form.Row>
                    <Form.InputGroup name="hapu" label="Select your hapu">
                        <select
                            name="hapu"
                            id="hapu"
                            className="dropdown"
                            defaultValue={hapu || "placeholder"}
                            onChange={(event) =>
                                setHapu(event.currentTarget.value)
                            }
                            disabled={selection.hapu.length === 0}>
                            <option value="placeholder" disabled>
                                Select your hapu
                            </option>
                            {selection.hapu.map(({ id, description }) => (
                                <option key={id} value={id}>
                                    {description}
                                </option>
                            ))}
                        </select>
                    </Form.InputGroup>
                </Form.Row>
            )}
            {error && <p className="error">{error}</p>}
            <Form.Actions>
                <Button variant="primary" submit disabled={!hapu}>
                    Save
                </Button>
                <Link to={ROUTES.membersDetails()} className="button tertiary">
                    Cancel
                </Link>
            </Form.Actions>
        </Form>
    );
};
