import type { ImageFormat } from "@plinknz/tah-website-elements";

interface Options {
    formats: {
        [key: string]: ImageFormat;
    };
    targetSize?: number;
}

export function getMedia(url: string, options?: Options): string {
    if (options) {
        const { targetSize, formats } = options;

        if (formats && targetSize) {
            // Sort from smallest to largest and return first one larger than target
            const targetFormat = Object.values(formats)
                .sort((a, b) => a.width - b.width)
                .find((item) => item.width > targetSize);

            if (targetFormat) {
                return targetFormat.url;
            }
        }
    }

    return url;
}
