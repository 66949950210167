import { useQuery } from "@apollo/client";
import * as React from "react";
import { Link } from "react-router-dom";
import { generate } from "shortid";
import {
    GET_FOOTER_QUERY,
    GET_NAVIGATION,
    getFetchPolicy,
    showLoad,
} from "@plinknz/tah-website-elements";
import type {
    FooterNavigationItem,
    FooterNavigationLink,
    NavigationData,
} from "@plinknz/tah-website-elements";
import { DynamicLink } from "../components/dynamic-link";
import { SOCIAL_LINKS } from "../config/social-links";

export interface FooterData {
    footer: FooterType;
}

interface FooterType {
    items: FooterNavigationItem[];
}

export const Footer = () => {
    const { data, loading, error } = useQuery<FooterData>(GET_FOOTER_QUERY, {
        fetchPolicy: getFetchPolicy(),
    });
    const { data: navData } = useQuery<NavigationData>(GET_NAVIGATION, {
        fetchPolicy: getFetchPolicy(),
    });
    const renderNavigationLink = ({
        title,
        slug,
        icon,
    }: FooterNavigationLink) => (
        <li key={generate()} className="footer-navigation-item">
            <DynamicLink to={slug} className="footer-link">
                {icon && (
                    <>
                        <i className={icon} />{" "}
                    </>
                )}
                {title}
            </DynamicLink>
        </li>
    );
    const renderNavigationItem = ({
        mainpage,
        pages,
    }: FooterNavigationItem) => (
        <div className="footer-navigation-set" key={generate()}>
            {mainpage && (
                <Link
                    to={mainpage.slug}
                    className="footer-link footer-navigation-title">
                    {mainpage.title}
                </Link>
            )}
            <ul className="footer-navigation-list">
                {pages?.map(renderNavigationLink)}
            </ul>
        </div>
    );
    const renderInfo = () => (
        <>
            <div className="footer-logo" />

            <address className="footer-address">
                <p className="footer-text">
                    <a className="footer-link" href="tel:0800672644">
                        0800 N RANGI or 0800 672 644
                    </a>{" "}
                    <br />
                    9am - 5pm Monday to Friday
                </p>
                <p className="footer-text">
                    1 Mountain Road, Ohakune Junction, Ohakune{" "}
                </p>
                <p className="footer-text">
                    Freepost Ngāti Rangi, PO Box 195, Ohakune 4660
                </p>
            </address>

            <p className="footer-copy footer-text">© 1992 - 2020 Ngati Rangi</p>
        </>
    );

    if (showLoad(loading)) return null;

    return (
        <div className="footer" data-testid="footer">
            {navData?.navigation?.action && (
                <div className="footer-layer secondary">
                    <div className="footer-inner || constrain-width large">
                        <Link
                            to={navData.navigation.action.link.slug}
                            className="button large secondary">
                            {navData.navigation.action.label.toUpperCase()}
                        </Link>
                        <p className="heading-5">
                            Register as a member of our whakapapa
                        </p>
                    </div>
                </div>
            )}
            <footer className="footer-layer">
                <div className="footer-inner || constrain-width large">
                    <div className="footer-sections">
                        <div className="footer-section bordered">
                            {renderInfo()}
                        </div>
                        <div className="footer-section footer-navigation">
                            {!error &&
                                data?.footer?.items.map(renderNavigationItem)}
                            {SOCIAL_LINKS.map(renderNavigationItem)}
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};
